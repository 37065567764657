<template>
  <div class="page-wrapper">
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant" />
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/tagline-fr.svg" class="tagline" alt="Tagline Tremblant" />
        <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/tagline-en.svg" class="tagline" alt="Tagline Tremblant" />
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang"
            >FR</router-link
          >
        </div>
        <a :href="$t('site.smt-entrepot-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant" /></a>
      <div class="mobile-cta">
        <a :href="$t('site.smt-entrepot-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">
        <button class="cta prune" @click.prevent="goback">Retour</button><br /><br />

        <h1 class="big-title">Modalités<br />VENTE ÉCLAIR</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">
            Deux billets de ski de 1 jour, valables du dimanche au vendredi, du 9 au 17 décembre 2021 et du 21 mars au 18 avril 2022.
          </li>
          <li class="regular-text">Deux billets de ski de 1 jour, valables du lundi au vendredi, du 10 janvier au 11 février 2022.</li>
          <li class="regular-text">Billets transférables (les billets peuvent être utilisés par deux personnes différentes ou être offerts en cadeau).</li>
          <li class="regular-text">TARIFICATION ADULTE (18 ans +) :</li>
          <li class="regular-text sub">Non valable les samedis. 89 $ par billet. Achat en paire seulement</li>
          <li class="regular-text sub">Non valable les samedis/dimanches. 85 $ par billet. Achat en paire seulement</li>
          <li class="regular-text">Redevance et taxes en sus.</li>
          <li class="regular-text">Ces tarifs, dates, redevance et taxes sont modifiables sans préavis.</li>
        </ul>

        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">En ligne ou par téléphone au 1-888-738-1777, du 8 décembre 2021, à 13 h, au 10 décembre 2021, à 13 h.</li>
          <li class="regular-text">Achat en paires seulement.</li>
          <li class="regular-text">Aucune limite d’achat.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">
            Rendez-vous au Service à la clientèle, au Centre aventure du Sommet des neiges, à la billetterie (carte d’identité avec photo obligatoire) ou
            aux bornes d’impression dès votre achat.
          </li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Non remboursables.</li>
        </ul>

        <h2 class="small-title">Passeport vaccinal et preuve de vaccination</h2>
        <p class="regular-text">
          Pour les détenteurs de billets (SkiMax, multi-jours, entrepôt, groupes, autres), la vérification se fera en personne à l’un de nos comptoirs de
          billetteries. Pour les
          <a href="https://www.tremblant.ca/planifiez/billets-passes/billets-de-ski?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=fr" target="_blank"
            >billets multi-jours</a
          >, une seule validation sera nécessaire pour l’ensemble des journées de validité de votre billet.
        </p>
      </div>

      <div v-else-if="$route.meta.lang === 'en'">
        <button class="cta prune" @click.prevent="goback">Back</button><br /><br />

        <h1 class="big-title">FLASH SALE <br />Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">
            Two 1-day lift tickets valid from Sunday to Friday, from December 9 to December 17, 2021, and from March 21 to April 18, 2022.
          </li>
          <li class="regular-text">Two 1-day lift tickets valid from Monday to Friday, from January 10 to February 11, 2022.</li>
          <li class="regular-text">Tickets are transferable (tickets can be used by 2 different persons or as a gift).</li>
          <li class="regular-text">PRICING ADULT (18 +) :</li>
          <li class="regular-text sub">Non valid on Saturdays. $89 per ticket. Purchase in pairs only</li>
          <li class="regular-text sub">Non valid on Saturdays and Sundays. $85 per ticket. Purchase in pairs only.</li>
          <li class="regular-text">Royalty and taxes extra.</li>
          <li class="regular-text">Rates, dates, royalty and taxes are subject to change without notice.</li>
        </ul>

        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Online or by phone from December 8, 2021, 1 p.m., to December 10, 2021, 1 p.m.</li>
          <li class="regular-text">Purchase in pairs only. No purchase limit.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">
            Guests must pick up their tickets at our Guest Services Desk, at the Centre Aventure in the Sommet des Neiges hotel, the ticket office (bring a
            valid photo ID) or ticket printing kiosks as of the day of your purchase.
          </li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Not refundable.</li>
        </ul>

        <h2 class="small-title">Vaccine passport and proof of vaccination</h2>
        <p class="regular-text">
          Day ticket holders (Ski-max, multi-day, and others) will need to validate vaccination on-site. A single validation will be necessary for
          <a
            href="https://www.tremblant.ca/plan/tickets-and-passes/winter-lift-tickets?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=en"
            target="_blank"
            >multi-day tickets</a
          >.
        </p>
      </div>
    </section>

    <footer-bar></footer-bar>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform" />
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              v-model="time1"
              name="arrivaldate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'arrivaldate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              v-model="time2"
              name="departuredate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'departuredate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input name="adultcount" type="number" value="2" min="1" required />
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input name="childCount" type="number" value="0" required />
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: 'Éclaté de haut en bas',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: '',
        },
        {
          name: 'twitter:image',
          content: '',
        },
      ],
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null,
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.snowscroll()
    })
  },
  methods: {
    goback() {
      return this.$router.go(-1)
    },
    table1() {
      this.toggle = true
    },
    table2() {
      this.toggle = false
    },
    showlodgingform() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
      }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
      if (this.sidepanel) {
        this.sidepanel = false
      } else {
        this.sidepanel = true
      }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${-150 + st / 2}px`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
